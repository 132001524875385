<template>
  <div class="parent">
    <div class="overlay-parent">
      <v-container class="px-5 overlay">
        <v-row>
          <v-col class="text-center">
            <div
              class="text-h4 mb-8 font-weight-bold text-uppercase custom-shadow"
            >
              Watch our latest sermons
            </div>
            <v-btn x-large :to="{ name: 'Watch' }">Watch Now</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-img
      :src="srcComputed"
      :lazy-src="imageLazy"
      class="background custom-darken-4"
    ></v-img>
  </div>
</template>
<script>
import imageLarge from "./66701599298__A3489AEF-5410-4508-B399-6385EA0F5E1C-cropped-6pxgauss-compress-85-large.webp";
import imageMobile from "./66701599298__A3489AEF-5410-4508-B399-6385EA0F5E1C-cropped-6pxgauss-compress-85-mobile.webp";
import imageLazy from "./66701599298__A3489AEF-5410-4508-B399-6385EA0F5E1C-cropped-6pxgauss-lazy.webp";

export default {
  name: "WatchNow",
  data: () => ({
    imageLazy,
  }),
  computed: {
    srcComputed() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return imageMobile;
        case "sm":
          return imageMobile;
        case "md":
          return imageLarge;
        case "lg":
          return imageLarge;
        case "xl":
          return imageLarge;
      }
      return imageLarge;
    },
  },
};
</script>
<style lang="scss" scoped>
.parent {
  position: relative;
  overflow: hidden;
}
.background {
  height: 424px;
  max-height: 85vh;
  width: 100%;
}
.overlay-parent {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  z-index: 1;
}
.overlay {
}
.custom-shadow {
  text-shadow: 2px 2px 11px rgba(0, 0, 0, 0.9);
}
</style>
