<template>
  <EventBase
    :max-height="null"
    :height="null"
    :min-height="null"
    backgroundColor="#002e4b"
  >
    <div class="parent">
      <v-img
        :src="banner"
        :lazy-src="bannerLazy"
        max-height="1000px"
        max-width="1000px"
        width="100vw"
        contain
        alt="Operation Toy Giveaway: Free Event! Saturday, December 14th, 10AM - 12AM. Toys, Crafts, Carnival Games, T-Shirts, Activities, Door Prizes. All for free! Come to: 15651 N 23rd Ave, Phoenix, AZ 85023"
      >
      </v-img>
    </div>
  </EventBase>
</template>
<script>
import EventBase from "../EventBase.vue";
import banner from "./giveaway-full.webp";
import bannerLazy from "./giveaway-lazy.webp";
export default {
  name: "ToyGiveAway",
  components: {
    EventBase,
  },
  data: () => ({ banner, bannerLazy }),
};
</script>
<style lang="scss" scoped>
.button {
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
}
.parent {
  position: relative;
}
.message {
  border-style: solid;
  border-width: 4px;
  border-color: #fdd205;
  // border-color: #054f25;
  max-width: 1000px;
  background-color: #fee17c;
  // color: white;
  margin-top: 16px;
  margin-bottom: 24px;
  .inner {
    margin: 16px;
  }
}
</style>
