<template>
  <div id="">
    <form
      action="https://eaglerockproducts.us5.list-manage.com/subscribe/post?u=49314df5734b71d88924e273f&amp;id=7949288a8f"
      method="post"
      target="_blank"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            type="email"
            value=""
            name="EMAIL"
            :rules="emailRules"
            label="Email address"
            dense
            :outlined="outlined"
            class="mb-0 pb-0"
            :dark="dark"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn :outlined="outlined" block small type="submit" :dark="dark"
            >Subscribe</v-btn
          >
        </v-col>
      </v-row>

      <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
      <div style="position: absolute; left: -5000px" aria-hidden="true">
        <input
          type="text"
          name="b_49314df5734b71d88924e273f_7949288a8f"
          tabindex="-1"
          value=""
        />
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: "MailChimpSignup",
  props: {
    outlined: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
  },
  data: () => ({
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
};
</script>
<style lang="scss" scoped></style>
