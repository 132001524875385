<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <div class="nav-drawer-icon pt-3">
        <img
          src="./assets/logo-for-webpage-nav-bar.svg"
          alt=""
          class="logo lightLogo mx-auto"
        />
      </div>

      <v-list nav>
        <v-list-item-group>
          <v-list-item
            v-for="item in menuItems"
            :key="item.routeName"
            :to="{ name: item.routeName }"
            exact
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.itemName }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      absolute
      :flat="fullScreenBackground"
      :color="fullScreenBackground ? 'transparent' : null"
      :dark="fullScreenBackground"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        aria-label="Open the Side Bar Menu"
      >
        <v-icon>{{ mdiMenu }}</v-icon>
      </v-app-bar-nav-icon>
      <img
        src="./assets/logo-for-webpage-nav-bar-text-only.svg"
        alt=""
        :class="fullScreenBackground ? 'logo darkLogo' : 'logo lightLogo'"
      />
      <v-spacer></v-spacer>
      <template v-slot:extension v-if="showTabs">
        <v-tabs align-with-title>
          <v-tab
            v-for="item in menuItems"
            :key="item.routeName"
            :to="{ name: item.routeName }"
            exact
            class="menu-item-shadow"
          >
            {{ item.itemName }}
          </v-tab>
        </v-tabs>
      </template>
      <!-- -->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main :class="homePageStyle">
      <!-- Provides the application the proper gutter -->
      <router-view></router-view>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import menuItems from "./menuItems";
import Footer from "./views/components/Footer.vue";
import { mdiMenu } from "@mdi/js";

export default {
  name: "App",
  components: {
    Footer,
  },
  data: () => ({
    mdiMenu,
    drawer: false,
    menuItems: menuItems,
  }),
  computed: {
    showTabs() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return [];
        case "xl":
          return [];
      }
      return [];
    },
    fullScreenBackground() {
      return [
        "Home",
        "Location",
        "StatementOfFaith",
        "Schedule",
        "Contact",
        "Watch",
        "Events",
        "Members",
      ].includes(this.$route.name);
    },
    homePageStyle() {
      if (this.fullScreenBackground) {
        return ["ma-0", "pa-0"];
      }
      return [];
    },
  },
};
</script>

<style scoped>
.menu-item-shadow {
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.9);
}
.logo {
  height: 50px;
}
.darkLogo {
  filter: grayscale(100%) brightness(1000);
}
.lightLogo {
}
.nav-drawer-icon {
  width: 100%;
  text-align: center;
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
</style>

<style>
.custom-darken-0 {
  filter: contrast(105%) brightness(90%);
}
.custom-darken-1 {
  filter: contrast(100%) brightness(85%);
}
.custom-darken-2 {
  filter: contrast(95%) brightness(80%);
}
.custom-darken-3 {
  filter: contrast(90%) brightness(75%);
}
.custom-darken-4 {
  filter: contrast(85%) brightness(70%);
}
.custom-darken-5 {
  filter: contrast(80%) brightness(65%);
}
.custom-darken-6 {
  filter: contrast(75%) brightness(60%);
}
.custom-darken-7 {
  filter: contrast(70%) brightness(55%);
}
.custom-darken-8 {
  filter: contrast(65%) brightness(50%);
}
.custom-darken-9 {
  filter: contrast(60%) brightness(45%);
}
.custom-darken-10 {
  filter: contrast(55%) brightness(40%);
}

.custom-shadow-0 {
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.9);
}
.custom-shadow-1 {
  text-shadow: 2px 2px 9px rgba(0, 0, 0, 0.9);
}
.custom-shadow-2 {
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.85);
}
.custom-shadow-3 {
  text-shadow: 2px 2px 11px rgba(0, 0, 0, 0.8);
}
.custom-shadow-4 {
  text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.7);
}
.custom-shadow-5 {
  text-shadow: 2px 2px 13px rgba(0, 0, 0, 0.6);
}
.custom-shadow-6 {
  text-shadow: 2px 2px 14px rgba(0, 0, 0, 0.5);
}
.custom-shadow-7 {
  text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4);
}
.custom-shadow-8 {
  text-shadow: 2px 2px 16px rgba(0, 0, 0, 0.3);
}
.custom-shadow-9 {
  text-shadow: 2px 2px 16px rgba(0, 0, 0, 0.2);
}
.custom-shadow-10 {
  text-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1);
}

.persistent-active-fix:focus::before {
  opacity: 0 !important;
}
.persistent-active-fix:hover::before {
  opacity: 0 !important;
}
</style>
