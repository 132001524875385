import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import Contact from "../views/contact/Contact.vue";
import ChurchLocation from "../views/location/ChurchLocation.vue";
import Schedule from "../views/schedule/Schedule.vue";
import Watch from "../views/watch/Watch.vue";
import StatementOfFaith from "../views/statement-of-faith/StatementOfFaith.vue";
import Members from "../views/members/Members.vue";
import Events from "../views/events/Events.vue";

/*

BE SURE TO UPDATE THE SITEMAP.XML!

*/

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Contact",
    },
  },
  {
    path: "/location",
    name: "Location",
    component: ChurchLocation,
    meta: {
      title: "Contact",
    },
  },
  {
    path: "/schedule-and-calendar",
    name: "Schedule",
    component: Schedule,
    meta: {
      title: "Schedule & Calender",
    },
  },
  {
    path: "/watch",
    name: "Watch",
    component: Watch,
    meta: {
      title: "Watch Our Sermons",
    },
  },
  {
    path: "/statement-of-faith",
    name: "StatementOfFaith",
    component: StatementOfFaith,
    meta: {
      title: "Statement Of Faith",
    },
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
    meta: {
      title: "Upcoming Events",
    },
  },
  {
    path: "/members",
    name: "Members",
    component: Members,
  },

  // Handle routes from old page
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/404",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
