import { render, staticRenderFns } from "./SlideShowOverlay.vue?vue&type=template&id=5a1fd3ce&scoped=true&"
import script from "./SlideShowOverlay.vue?vue&type=script&lang=js&"
export * from "./SlideShowOverlay.vue?vue&type=script&lang=js&"
import style0 from "./SlideShowOverlay.vue?vue&type=style&index=0&id=5a1fd3ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a1fd3ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VResponsive,VRow})
