// https://stackoverflow.com/questions/57552261/vuetifyjs-adding-only-used-icons-to-build
// https://vuetifyjs.com/en/features/icon-fonts/#install-material-design-icons-js-svg
import {
  mdiHome,
  mdiMapMarker,
  mdiCalendar,
  mdiYoutube,
  mdiCalendarStar,
  mdiTextBoxCheckOutline,
  mdiPhone,
  mdiAccountGroup,
} from "@mdi/js";

export default [
  { itemName: "Home", routeName: "Home", icon: mdiHome },
  { itemName: "Location", routeName: "Location", icon: mdiMapMarker },
  {
    itemName: "Schedule & Calendar",
    routeName: "Schedule",
    icon: mdiCalendar,
  },
  { itemName: "Watch", routeName: "Watch", icon: mdiYoutube },
  { itemName: "Events", routeName: "Events", icon: mdiCalendarStar },
  {
    itemName: "Statement Of Faith",
    routeName: "StatementOfFaith",
    icon: mdiTextBoxCheckOutline,
  },
  { itemName: "Contact", routeName: "Contact", icon: mdiPhone },
  { itemName: "Members", routeName: "Members", icon: mdiAccountGroup },
];
