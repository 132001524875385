<template>
  <div>
    <PageHeadingFullScreen
      :src="headerImage"
      :srcMobile="headerImageMobile"
      :srcLazy="headerImageLazy"
      title="Watch"
      filter="brightness(85%) contrast(90%)"
    ></PageHeadingFullScreen>
    <div align="center">
      <div class="mt-6 ma-4 mx-sm-6 top-text" align="left">
        <v-alert dense type="info" :icon="mdiInformation" class="darken-2">
          You can find our most recent sermons below, and all of our sermons on
          our
          <a
            href="https://www.youtube.com/c/ACCNorthPhoenix/videos?view=0&sort=dd&flow=grid"
            target="_blank"
            class="link"
            >YouTube channel</a
          >.
        </v-alert>
      </div>
      <v-card
        v-for="video in youtubeVideos"
        :key="video.id.videoId"
        class="ma-4 ma-sm-6"
        max-width="800"
        color="#f5f5f5"
      >
        <youtube :fitParent="true" :video-id="video.id.videoId"></youtube>
        <v-card-title
          class="text-subtitle-1 point-on-hover"
          @click="openVideo(video.id.videoId)"
        >
          {{ video.snippet.title }}
        </v-card-title>
      </v-card>
    </div>
  </div>
</template>

<script>
import PageHeadingFullScreen from "../components/PageHeadingFullScreen.vue";
import { mapActions, mapGetters } from "vuex";

import headerImage from "./sanctuary-pic-large.webp";
import headerImageMobile from "./sanctuary-pic-mobile.webp";
import headerImageLazy from "./sanctuary-pic-lazy.webp";

import { mdiInformation } from "@mdi/js";

export default {
  name: "Watch",
  components: {
    PageHeadingFullScreen,
  },
  data() {
    return {
      mdiInformation,
      headerImage,
      headerImageMobile,
      headerImageLazy,
    };
  },
  created() {
    // Search requests cost 100 quota units of 10,000 per day, so it's best to minimize requests
    // https://developers.google.com/youtube/v3/getting-started#quota
    if (this.youtubeVideos.length == 0) {
      this.getYoutubeVides();
    }
  },
  computed: {
    ...mapGetters(["youtubeVideos"]),
  },
  methods: {
    ...mapActions(["getYoutubeVides"]),
    openVideo(id) {
      let url = `https://www.youtube.com/watch?v=${id}`;
      return window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.top-text {
  max-width: 800px;
}
.point-on-hover {
  cursor: pointer;
}
.link {
  color: white;
}
</style>
