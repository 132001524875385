<template>
  <div>
    <div class="mapouter" :width="width" :height="height">
      <div class="gmap_canvas">
        <iframe
          title="Map to the North Phoenix ACC Church."
          :width="width"
          :height="height"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=ACC%20North%20Phoenix%2015651%20N%2023rd%20Ave,%20Phoenix,%20AZ%2085023&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          class="elevation-2"
        ></iframe>
        <a href="https://getasearch.com"></a>
        <br />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    width: { type: String, default: "100%" },
    height: { type: String, default: "100%" },
  },
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.mapouter {
  position: relative;
  text-align: right;
}
#gmap_canvas {
  overflow: hidden;
  background: none !important;
}
</style>
